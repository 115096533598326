<template>
  <div class="infoDetails">
    <!-- 头部 -->
    <van-nav-bar fixed placeholder z-index="100" title="评论详情" left-text="返回"  left-arrow @click-left="onClickLeft" />

    <ul>
        <post-list v-for="item in list"
                     :key="item.id"
                      @showOperation="showOperation"
                      @handleshare="handleshare(item)"
                      @jumpdetails="jumpdetails(item)"
                     :item="item"/>
      </ul>

    <div class="kong-comment" v-if="isshow==true">
       <p>{{alltotal}}条回复</p>
       <div class="text">暂无人回复该评论，赶快来抢沙发吧</div>
    </div>
    
    <div class="lock" @click="jumpLogin" v-if="lockisshow">
       <div class="lockTxt"><img src="../assets/image/lock.png" alt="">登录即可解锁查看</div>
    </div>

    <div class="son-content" v-if="show1==false">
       <p>{{alltotal}}条回复</p>
       <ul class="content-ul">
         <li class="content-item clearfix" v-for="(item,index) in commentList" :key="index">
           <div class="header">
             <div class="image">
             <van-image
                round
                cover
                fit="cover"
                :src="item.headImg ? item.headImg :'https://i.loli.net/2021/11/03/bDNUWZFYLO76kjJ.png'"
              />
           </div>
           <p class="title">{{item.nickName}}</p>
           <p class="icon">
             <span class="del" @click="delcomment(item)"><van-icon name="delete-o" size="15px"/></span>
             <span @click="Report(item)"><van-icon name="warning-o"  size="15px"/></span>
             <span @click="parise(item)" :class="item.endorse==false?'fonthui':'fontred'">{{item.endorseCount}}<span :class="item.endorse==false?'hui':'red'"></span></span>
           </p>
           </div>
           <p class="text-content" @click="getid(item.commentId,item.nickName)" v-html="item.commentContent"></p>
            <p class="time">{{item.replyTime}}</p>
            <p class="reply" v-if="item.comments.length !== 0">回复({{item.commentsList.length}})</p>

            <div class="child" v-if="item.comments.length !== 0">
               <div class="child-item"  v-for="son in item.commentsList" :key="son.id">
                  <div class="header">
                      <div class="image">
                      <van-image
                        round
                        cover
                        fit="cover"
                        :src="son.headImg ? son.headImg :'https://i.loli.net/2021/11/03/bDNUWZFYLO76kjJ.png'"
                      />
                    </div>
                    <p class="title" v-html="son.showNickName"></p>
                      <p class="icon">
                      <span  class="del" @click="delcomment(son)"><van-icon name="delete-o" size="15px"/></span>
                      <span @click="Report(son)" style="height:20px;width:20px"><van-icon name="warning-o"  size="15px"/></span>
                      <span @click="parise(son)" :class="son.endorse==false?'fonthui':'fontred'">{{son.endorseCount}}<span :class="son.endorse==false?'hui':'red'"></span></span>
                    </p>
                  </div>
                   <p class="text-content" @click="getid(son.commentId,son.nickName)">{{son.commentContent}}</p> 
                   <p class="time">{{son.replyTime}}</p>
                </div>
            </div>

         </li>
       </ul>
    </div>
    <div style="height:2rem"></div>

    <van-search v-model="value" @blur="searchShow" ref="gain" placeholder="写条评论吧~" >
      <template #left-icon>
         <img src="../assets/image/comment-pen.png" alt="">
         <span class="searchTxt" v-if="searchTxt">回复{{nickName}}</span>
      </template>
    </van-search>
     
  </div>
</template>

<script>
import PostList from "../components/community/PostList";
import { communityDetails,CommentDetails,addComment,Collection,delComment} from "@api/community.js";
import { pariseadmin } from "@api/servicemall.js";
import qs from "qs";
  export default {
     components:{
       PostList
     },
     data(){
       return{
           id:'',
           list:[],
           show:false,
           commentList:'',
           value:'',
           parentCommentId:'',
           isshow:'',
           show1:'',
           alltotal:'',
           nickName:'',
           searchTxt:false,
           //更多
           operation: false,
           userId:"",
           lockisshow:false,
           type:"",
           tabType:"",
           pageType:'',
           UserpersonalId:""
       }
     },
     methods:{
       //  返回
      onClickLeft() {
        if(this.pageType == 1){
          this.$router.push({
              name:'Communityadmin',
              query:{
                tabType:this.tabType
              }
          })
        }else if(this.pageType == 2){
          this.$router.push({
              name:'dynamic',
              query:{
                tabType:this.tabType
              }
          })
        }else{
          this.$router.push({
              name:'Userpersonal',
              query:{
                id:this.UserpersonalId,
                tabType:this.tabType,
              }
          })
        }
      },
      jumpdetails(){
          let input = document.getElementsByClassName("van-field__control")[0]
          input.focus()
      },
      jumpLogin(){
         this.$router.push({
           name:'login'
         })
      },
      //点赞
      async parise(item){
        const data = {
          praiseType:2,
          targetId:item.commentId
        }
        const result = await pariseadmin(data)
        if(result.code === 0){
          console.log(result.data)
          if(result.data){
            this.$toast("亲，点赞成功啦！")
          }else{
            this.$toast("亲，取消点赞噢！")
          }
           this.getComment()
        }
      },

      //删除评论
      async delcomment(item){
         if(item.userId != this.userId){
             this.$toast("请选择自己的评论删除噢~")
             return
         }
         const info = {
           commentId:item.commentId,
           dismainId:item.dismainId,
         }
         const res = await delComment(info)
         if(res.code === 0 ){
           this.$toast("删除成功！")
           window.location.reload();
         }else{
           this.$toast("删除失败,请联系管理员！")
         }
      },
      //收藏
      async postCollect(item){
         let obj = ""
         item.map(item=>{
            obj = item
         })
         const info = {
           collectType:1,
           targetId:obj.id,
           type:obj.postType,
           userId:obj.userid
         }
         const result = await Collection(qs.stringify(info))
         if(result.code === 0 ){
           this.$toast(result.msg)
           this.operation = false
         }
      },
      Report(item){
        this.$router.push({
          name:"complaint",
          query:{
            id:item.commentId,
            type:5
          }
        })
      },
      postReport(item){
         this.$router.push({
          name:"complaint",
          query:{
            id:item[0].id,
            type:1
          }
        })
      },
      //分享
    handleshare(item){
      let txt = "http://"+window.location.host+"/#/CommunitDetails?id="+item.id
      if(window.android){
            const obj = {
              action:'share',
              data:{
                type:3,
                content:txt,
              }
          }
          window.android.postMessage(JSON.stringify(obj));
      }else if(window.webkit){
         // ios 环境下
        const obj = {
          action: "share",
          data: {
            type: 3,
            content:txt,
          },
        };
        let jsonStr = JSON.stringify(obj);
        window.webkit.messageHandlers.sendMessageForVue.postMessage(jsonStr);
      }
    },
    
      //屏蔽
      async postShield(item){
         let obj = ""
         item.map(item=>{
            obj = item
         })
         const info = {
           collectType:2,
           targetId:obj.id,
           type:obj.postType,
           userId:obj.userid
         }
         const result = await Collection(qs.stringify(info))
         if(result.code === 0 ){
           this.$toast(result.msg)
           this.operation = false
         }
      },

      async getDetails(){
         const result = await communityDetails(this.id,this.type)
         if(result.code === 0 ){
           this.list.push(result.data)
         }
      },

      async getComment(){
         const data = {
           dismainId : this.id
         }
         const result = await CommentDetails(data)
         if(result.code === 0 ){
           this.commentList = result.data
           if(this.commentList.length === 0 ){
               this.isshow = true
               this.show1 = true
               this.alltotal = this.commentList.length
           }else{
               this.isshow = false
               this.show1 = false
               this.alltotal = this.commentList.length
              
               this.commentList.forEach(element => {
                  element.commentsList = []
                  if (element.comments || element.comments.length > 0) {
                    flatTree(element.comments);
                    function flatTree(comments) {
                      if (!comments || comments.length === 0) return [];
                      comments.forEach(comment => {
                        element.commentsList.push(comment);
                        return flatTree(comment.comments);
                      });
                    }
                  }
                });

                this.commentList.forEach(element => {
                  if (element.comments || element.comments.length > 0) {
                    element.comments.forEach(comments => {
                      for (let i = 0; i < element.commentsList.length; i++) {
                        const com = element.commentsList[i];
                        element.commentsList[i].showNickName = com.nickName
                        let obj = element.commentsList.find(item => item.commentId== com.parentCommentId)
                        if (obj) {
                          element.commentsList[i].showNickName = `${com.nickName} <span class:among>回复</span> ${obj.nickName}`
                        }
                      }
                    });
                  }
                });
              }
          }
      },
      showOperation(){
        this.operation = true
      },
      openMore(){
           this.show = true
      },
      searchShow(){
             this.searchTxt = false
             this.onsubmit()
      },
      getid(parentCommentId,nickName){
          this.parentCommentId = parentCommentId
          this.nickName = nickName
          let input = document.getElementsByClassName("van-field__control")[0]
          input.focus()
          this.searchTxt = true
      },
      //添加评论
      async onsubmit(){
        if(this.value == ""){
          this.$toast("请输入内容再评价噢")
          return
        }
        const data = {
          commentContent:this.value,
          dismainId:this.id,
          parentCommentId:this.parentCommentId,
        }
        const result = await addComment(data)
        if(result.code === 0 ){
          this.$toast("评论成功")
          this.getComment()
          this.value = ""
          this.$router.go(0)
          this.searchTxt = false
        }
      },
      ifisshow(){
        if(JSON.parse(localStorage.getItem("userInfo"))){
           this.lockisshow = false
        }else{
           this.lockisshow = true
        }
      }
     },
     created(){
         sessionStorage.setItem("postType",1)
         this.ifisshow()
         this.id = this.$route.query.id
         this.type = this.$route.query.type
         this.getDetails()
         this.getComment()
         this.userId =  JSON.parse(localStorage.getItem("userInfo")).userId
         this.tabType = this.$route.query.tabType
         this.pageType = this.$route.query.pageType
         this.UserpersonalId = this.$route.query.userId
     },
     destroyed(){
         sessionStorage.removeItem("postType")
     },
  }
</script>

<style lang="scss" scoped>
.infoDetails{
        font-family: PingFang SC;
    ::v-deep .van-nav-bar__text{
      color: #333333;
      font-size: 15px;
      font-weight: 500;
    }
    ::v-deep .van-nav-bar .van-icon{
      color: #333333;
    }
    ::v-deep .van-nav-bar__title{
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    ::v-deep .van-nav-bar__content{
      height: 57px;
    }
       .more{
      position: absolute;
      right: 10px;
      top: 25px;
      width:13px;
      height: 13px;
      img{
        width: 100%;
        height: 100%;
      }
    }

     .morePopup{
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      li{
        flex: 1;
        span{
          display: inline-block;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #333333;
          transform: translateY(-4px);
        }
      }
    }
  >p{
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #888888;
    margin-top: 20px;
    margin-left: 16px;
  }
   .kong-comment{
      height: 200px;
      position: relative;
      p{
        margin-top: 10px;
        margin-left: 20px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #888888;
      }
      .text{
        width: 204px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #CCCCCC;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }
    }
    .son-content{
      >p{
        margin-top: 10px;
        margin-left: 20px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #888888;
      }
      .content-item{
        margin-top: 30px;
        position: relative;
        .header{
          display: flex;
          align-items: center;
           .image{
           width:50px;
           margin-left: 17px;
           height: 50px;
           display: inline-block;
           img{
             width: 100%;
             height: 100%;
             border-radius: 50%;
           }
          }
          .title{
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #4E4E4E;
            display: inline-block;
            margin-left: 10px;
            width: 200px;
          }
          .icon{
          font-size: 10px;
          font-family: PingFang SC;
          color: #A7A7A7;
          .del{
            margin-right: 10px;
          }
          >span:last-of-type{
            margin-left: 10px;
            transform: translateY(-2px);
            span{
              margin-left: 5px;
              transform: translateY(1px)
            }
             .red{
                width: 15px;
                height: 15px;
                background: url('../assets/image/red-zan.png') no-repeat;
                background-size: 100% 100%;
              }
              .hui{
                width: 15px;
                height: 15px;
                background: url('../assets/image/comment-dianzan.png') no-repeat;
                background-size: 100% 100%;
              }  
          }
          span{
            display: inline-block; 
          } 
          .fontred{
            color:#EB441E;
          }  
          .fonthui{
             color: #A7A7A7;
          }
        }

        } 
       
        .time{
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
          text-align: right;
          padding-right: 10px;
          margin-top: 20px;
        }
        .reply{
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
          margin-left: 75px;
          transform: translateY(-17px);
        }
        .text-content{
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #4E4E4E;
          width: 200px;
          word-break:break-all;
          margin-left: 75px;
        }
        
        .child-item{
          margin-left: 58px;
          margin-top: 20px;
          position: relative;
          .time{
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #999999;
          }
          .title{
            width: 138px;
          }
        }
      }
    }
    ::v-deep .van-field__left-icon{
      margin-top:4px;
      img{
        width: 18px;
        height: 18px;
      }
    }
    ::v-deep .van-search__content{
      border-radius: 18px;
      background: #EFEFEF;
    }
    ::v-deep .van-search{
      position: fixed;
      width: 100%;
      bottom: 0px;
    }
    .searchTxt{
      display: inline-block;
      margin-left: 5px;
      transform: translateY(-4px);
    }
    ::v-deep .van-search{
      .van-icon{
        margin-top: 3px;
      }
    }
}
.lock{
  position:fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 380px;
  background: #fff;
  z-index: 666;
  display: flex;
  justify-content: center;
  align-items: center;
  .lockTxt{
     img{
        width: 18px;
        height: 18px;
        transform: translateY(2.5px);
        margin-right: 5px;
      }
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #4587FF;
  }
}
</style>
<style scoped>
  .van-popup{
  height: 174px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.popup-operation{
  height: 228px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.popup_item{
  font-size: 14px;
  margin-left: 20px;
  margin-top: 20px;
  flex: 1;
}
.popup_item > img{
  width: 20px;
  height: 20px;
}
.popup_item > span{
  margin-left: 20px;
}
::v-deep .van-image--round{
    width: 100%;
    height: 100%;
}
</style>